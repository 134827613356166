:root {
    --debug-visible: none;
}

.error-message {
    font-size: 0.9em;
    line-height: 1.2em;
    color: red;
    margin-left: 20px;
}

.debug_only {
    display: var(--debug-visible);
}

.speedmaster-login form {
    display: flex;
    flex-direction: row;
}

.speedmaster-login #submit-btn {
    margin-top: calc(1.2em + 20px);
}

.lost-password>div>span {
    white-space: pre;
}

.login_failed_msg {
    white-space: pre-line;
    display: inline-block;
}

html {
    box-sizing: border-box;
}

.password-security-container {
    margin-top: 3px;
}

*,
*:before,
.speedmaster-login *:after {
    box-sizing: inherit;
    font-family: 'Open Sans', sans-serif !important;
}

body {
    font-family: 'Open Sans', sans-serif !important;
    font-size: 100%;
    line-height: 1.125em;
    position: relative;
    color: #444444;
    margin: 0;
}

header,
footer {
    background-color: #434B4D;
    max-width: 1040px;
    margin: 0 auto;
    overflow: auto;
}

header {
    height: 135px;
}

footer {
    margin-top: 80px;
}

header:after,
footer:after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    background-color: #434B4D;
    z-index: -1;
}

header:after {
    top: 0;
    height: 135px;
}

footer:after {
    bottom: 0;
    height: 210px;
}

.logo {
    margin: 30px 0 0 20px;
    text-indent: -9999px;
    height: 74px;
    width: 353px;
    background: url("img/login-speedmaster/logo.png") no-repeat;
    background-size: 100%;
    display: block;
}

.wrapper {
    background: url("img/login-speedmaster/header.jpg") no-repeat;
    background-position: center right;
    height: 400px;
}

.wrapper div,
.main {
    max-width: 1040px;
    margin: 0 auto;
}

div.img {
    width: 40%;
    max-width: 100%;
    padding: 280px 0 0 20px;
    background: url("img/login-speedmaster/label.png") no-repeat 20px bottom/376px;
    margin: 0;
}

h1 {
    font-size: 1.9em;
    border-bottom: 1px solid #ddd;
    line-height: 80px;
    padding-left: 20px;
    font-weight: 300;
    color: #444444;
}

h1:after {}

h2 {
    font-size: 1.4em;
    margin: 30px 20px 21px 20px;
    font-weight: 300;
    line-height: 1.2em;
    color: #444444;
}

p {
    margin: 0 20px;
    font-size: 0.9em;
    color: #888888;
    line-height: 1.2em;
}

p a {
    /* text-transform: uppercase; */
    text-decoration: none;
    color: #9c1006;
}

form {}

input:not([type="checkbox"]) {
    height: 38px;
    width: 30%;
    max-width: 220px;
    margin-right: 15px;
    font-size: 1.125em;
    padding-left: 10px;
    border: 1px solid #434B4D;
    -webkit-appearance: none;
    border-radius: 0;
}

input[type=submit],
#submit-btn {
    width: 50px;
    border-radius: 0;
    border: none;
    background-color: #434B4D;
    color: #fff;
    display: inline-block;
    box-shadow: none;
}

.error {
    color: #e2001a;
    margin: 0;
}

b {
    font-weight: bold;
}

address {
    margin: 60px 60px 60px 20px;
    color: #fff;
    font-size: 0.8em;
    float: left;
}

address ul {
    list-style: none;
}

address ul,
address li {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

address a {
    text-decoration: none;
    color: #fff;
}

address:nth-child(2) {
    margin-right: 0;
}

.login-speedmaster {
    width: 1040px;
    margin: 42px 20px 21px 20px;
}

.cl-button-animated {
    cursor: pointer;
    transition: all .2s cubic-bezier(.175, .885, .32, 1.275);
    user-select: none;
}

.cl-button {
    position: relative;
    height: 35px;
    padding-left: 15px;
    padding-right: 15px;
    margin: 5px;
    text-align: center;
    font-size: 1em;
    line-height: 35px;
}

.lost-password {
    max-width: 400px;
    margin: 0 auto;
}

/* Form Elements */
.login-speedmaster form div.parameter_input_field:first-of-type {
    margin-right: 5px;
}

input.invalid {
    background-color: #fca6b6;
    background: linear-gradient(90deg, lightgray 40px, #fca6b6 40px);
}

.cl-input-header-text {
    position: relative;
    width: 100%;
    line-height: 1.2em;
    font-size: .9em;
    color: #7d899d;
}

.cl-input-header {
    position: relative;
    width: 96%;
    padding-left: 2%;
    padding-right: 2%;
}

.cl-input-icon-no-icon-left {
    background: linear-gradient(90deg, lightgray 40px, white 40px);
}

.cl-input-icon-email-left {
    background-image: url(img/sidemenu-icon-mail.svg);
    background-size: 22px;
    background-position: 8px;
    background-repeat: no-repeat;
}

.full-width {
    width: 100% !important;
    max-width: none !important;
}

.link_list {
    margin-left: 20px;
    margin-top: 10px;
}

.link_list a {
    color: #9c1006;
    display: inline;
}

.link_list a::after {
    display: block;
    content: ' ';
}

.link_list p {
    margin-left: 0px;
}

.form_errors {
    background-color: lightgray;
    border: thin solid gray;
    margin-top: 10px;
    margin-left: 20px;
    padding-top: 10px;
    margin-right: 20px;
}

.form_errors li {
    color: red;
}

.form-component {
    display: flex;
    width: 100%;
    max-width: 100%;
    margin-left: 20px;
    /* background-color: lightgray; */
}

.form-component>.cl-button {
    margin-top: 17.5px;
    height: 38px;
    border-radius: 0;
    border: none;
    background-color: #434B4D;
    color: #fff;
    display: inline-block;
    box-shadow: none;
}

.explanation {
    padding-bottom: 10px;
}

.moved-left {
    margin-left: 20px;
}

.account-data {
    flex-direction: column;
    width: 60%;
}

.moved-left .account-data div.parameter_input_field:first-of-type {
    margin-right: 0px;
}

/* /Form Elements */
@media only screen and (max-width: 480px) {
    input {
        display: block;
        width: 100%;
        margin: 0 0 20px 0;
        max-width: inherit;
    }

    address:nth-child(2) {
        margin-top: 0;
    }

    .wrapper {
        height: 200px;
        background-size: cover;
    }

    div img {
        padding-top: 140px;
        width: 50%;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 481px) {
    .wrapper {
        height: 300px;
        background-size: cover;
    }
}