.loginWrapper {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 117px;
    width: 100vw;
    height: 100vh;
    padding: 27px 24px;
    background-color: #F8FAFB;
}

.loginWrapper .imageWrapper {
    position: relative;
    background-image: url(./../../img/login-speedmaster/main-image.png);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 18px;
    width: 100%;
    height: 100%;
}

.loginWrapper .imageWrapper .topLeft {
    position: absolute;
    top: 24px;
    left: 17px;
}

.loginWrapper .imageWrapper .bottomLeft {
    position: absolute;
    bottom: 13px;
    left: 14px;
}

.loginWrapper .imageWrapper .bottomRight {
    position: absolute;
    bottom: 24px;
    right: 17px;
}

.loginWrapper .formWrapper {
    max-width: 450px;
    margin-top: 129px;
}

.loginWrapper .formWrapper .loginTitle {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    color: #6D6D6D;
    margin-bottom: 8px;
}

.loginWrapper .formWrapper .loginDesc {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: #8D8D8D;
    margin-bottom: 42px;
}

.loginWrapper .formWrapper form {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
}

.loginWrapper .formWrapper form .parameter_input_field .cl-input-header-text {
    margin-bottom: 8px;
}

.loginWrapper .formWrapper form .parameter_input_field .cl-input-header-text span {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    color: #4E4E4E;
}

.loginWrapper .formWrapper form .parameter_input_field .cl-input-icon-no-icon-left {
    background: transparent;
}

.loginWrapper .formWrapper form .parameter_input_field input {
    height: 44px;
    padding: 10px !important;
    border-radius: 8px;
    border: 1px solid #D8D8D8;
    background: #FFF;
    outline: none;
    font-size: 16px;
    font-weight: 400;
    color: #6D6D6D;
}

.loginWrapper .formWrapper form .parameter_input_field input.invalid {
    border-color: #E70303;
}

.loginWrapper .formWrapper form .parameter_input_field input::placeholder {
    color: #B2B2B2;
}

.loginWrapper .formWrapper form .passwordWrapper {
    position: relative;
}

.loginWrapper .formWrapper form .passwordWrapper .icon {
    position: absolute;
    right: 10px;
    top: 37px;
    display: flex;
    width: 24px;
    height: 24px;
    padding: 4.26px 1.5px;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: none;
    background-repeat: no-repeat;
    background-size: contain;
}

.loginWrapper .formWrapper form .passwordWrapper .icon.eye {
    background-image: url(./../../img/Eye.svg);
}

.loginWrapper .formWrapper form .passwordWrapper .icon.eyeSlash {
    background-image: url(./../../img/EyeSlash.svg);
}

.loginWrapper .formWrapper form .actionsWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.loginWrapper .formWrapper form .actionsWrapper .checkboxWrapper {
    display: flex;
    align-items: center;
    column-gap: 8px;
}

.loginWrapper .formWrapper form .actionsWrapper .checkboxWrapper input {
    margin: 0;
    width: 16px;
    height: 16px;
}

.loginWrapper .formWrapper form .actionsWrapper .checkboxWrapper label {
    font-size: 14px;
    font-weight: 600;
    color: #8D8D8D;
    cursor: pointer;
}

.loginWrapper .formWrapper form .actionsWrapper .forgotPass {
    font-size: 14px;
    font-weight: 600;
    color: #319DFF;
    cursor: pointer;
}

.loginWrapper .formWrapper form .submitButton {
    display: flex;
    padding: 9px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: #319DFF;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 700;
    color: #FFF;
    margin-top: 30px;
    cursor: pointer;
}

.loginWrapper .formWrapper form .registerAccount {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
    margin-top: 6px;
    font-size: 16px;
    font-weight: 600;
    color: #8D8D8D;
}

.loginWrapper .formWrapper form .registerAccount span {
    color: #319DFF;
    cursor: pointer;
}